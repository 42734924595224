import dynamic from 'next/dynamic';

export const SECTIONS = {
  ImageSection: dynamic(() => import('@organisms/ImageSection/ImageSection')),
  TextImageSection: dynamic(
    () => import('@organisms/TextImageSection/TextImageSection')
  ),
  TextSection: dynamic(() => import('@organisms/TextSection/TextSection')),
  TextVideoSection: dynamic(
    () => import('@organisms/TextVideoSection/TextVideoSection')
  ),
  VideoSection: dynamic(() => import('@organisms/VideoSection/VideoSection')),
  CtaButtonsSection: dynamic(
    () => import('@organisms/CtaButtonsSection/CtaButtonsSection')
  ),
  AccessibilitySection: dynamic(
    () => import('@organisms/AccessibilitySection/AccessibilitySection')
  ),
  ImageSliderSection: dynamic(
    () => import('@organisms/ImageSliderSection/ImageSliderSection')
  ),
  FaqSection: dynamic(() => import('@organisms/FaqSection/FaqSection')),
  QuoteSection: dynamic(() => import('@organisms/QuoteSection/QuoteSection')),
  EventRegistrationSection: dynamic(
    () => import('@organisms/EventRegistrationSection/EventRegistrationSection')
  ),
  ContentCarouselSection: dynamic(
    () => import('@organisms/ContentCarouselSection/ContentCarouselSection')
  ),
  ExploreFormSection: dynamic(
    () => import('@organisms/ExploreFormSection/ExploreFormSection')
  ),
  EventCarouselSection: dynamic(
    () => import('@organisms/EventCarouselSection/EventCarouselSection')
  ),
  NewsletterSection: dynamic(
    () => import('@organisms/NewsletterSection/NewsletterSection')
  ),
  StoreMapCtaSection: dynamic(
    () => import('@organisms/Store/MapCtaSection/StoreMapCtaSection')
  ),
  IframeSection: dynamic(
    () => import('@organisms/IframeSection/IframeSection')
  ),
  SegmentationSection: dynamic(
    () => import('@organisms/SegmentationSection/SegmentationSection')
  ),
  MapSection: dynamic(() => import('@organisms/MapSection/MapSection')),
};
